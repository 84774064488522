// Axios to help with data+endpoints
const axios = require("axios")

import { switchButtonState, formURL, convertFormToJSON } from "./utils.js"
import { renderError } from "./render.js"
import { router, authType } from "../app"
import JustValidate from "just-validate"
import { setAuthFields } from "./cookies.js"
import { gEvent } from "./reporting.js"

const CLIENTID = process.env.APP_CLIENTID
const ORIGINATINGAPP = process.env.APP_ORIGINATINGAPP
const TEMPLATEID = process.env.APP_TEMPLATEID
const OTPEXPIRYMINUTES = process.env.APP_OTP_EXPIRY_MINUTES

let authObj = ""

export function authpage() {

    if (authType == "OTP" || "COMBO") {

        // Read in the lifetime of an OTP message
        let OTPTimeout = ""
        let OTPExpiryInSeconds = OTPEXPIRYMINUTES * 60

        // This sets up a progress bar on the page to start timing down to the OTP is expired
        // TODO: Set the OTP expiry time to a cookie value - then calculate how many seconds we are away from that. Rerfresh the cookie val when new code is requested
        function progress(timeleft, timetotal, $element) {

            $("#progress").attr("max", timetotal).val(timeleft).html(`${timeleft} seconds remaining`)

            if (timeleft > 0) {
                OTPTimeout = setTimeout(function() {
                    progress(timeleft - 1, timetotal, $element)
                }, 1000)
            }
            if (timeleft == 0) {
                $("#verify-code").prop("disabled", true).html("Expired");
            }
        }

        // call the progress function to start the progress bar
        progress((OTPExpiryInSeconds - 5), OTPExpiryInSeconds, $('#progress'))

        // User requests a new/replacement OTP code
        $("#request-new-code-link").on("click", function() {
            // disable link
            switchLinkState("sending")

            // try to call endpoint
            axios.post(formURL("reqOTP"), {
                    "client": CLIENTID,
                    "originatingApp": ORIGINATINGAPP,
                    "templateId": TEMPLATEID,
                    "clientData": ""
                })
                .then(function(response) {
                    // if the endpoint replies "all good" use axios to route to OTP and enable link
                    switchLinkState("sent")

                    // reset the OTP timer
                    clearTimeout(OTPTimeout);

                    //reset the progress bar
                    progress(OTPExpiryInSeconds, OTPExpiryInSeconds, $('#progress'))

                    // set the button to enabled, reinstating the original content
                    $("#verify-code").prop("disabled", false).html(`<span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span> <span>Verify code</span>`)

                    // add event to GA
                    gEvent("Resend OTP success", "Verification", "Resend OTP success label")
                })
                .catch(function(error) {
                    // show error if failed and enable button
                    switchLinkState("failed")

                    // add event to GA
                    gEvent("Resend OTP failed", "Verification", "Resend OTP failed label")

                    console.log(error)
                });
        })
    }

    // Select the correct form validation for this particular recipient
    if (authType == "OTP") {
        setupOTPFormValidation()
    } else if (authType == "COMBO") {
        setupCOMBOFormValidation()
    } else {
        setupFormValidation()
    }
}

// Configure validation rules for an OTP form
function setupOTPFormValidation() {
    const validation = new JustValidate('#auth-form', {
        // these blank settings remove the default annoying css set by the library, using Bootstrap classes instead
        errorFieldStyle: '',
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: '',
        errorLabelCssClass: 'invalid-feedback',
        focusInvalidField: true,
        lockForm: true,
    });

    // add one or more rule for each field that needs validating
    validation
        .addField('#a_otp', [{
            rule: 'required',
            errorMessage: 'This field is required',
        }, ])
        .onSuccess((event) => {
            event.preventDefault();

            // form is valid, so...
            // call the submit function!
            submit_form()
        })
        .onFail((fields) => {

            // form not valid, so...
        })
}

// Configure validation rules for an OTP form
function setupCOMBOFormValidation() {
    const validation = new JustValidate('#auth-form', {
        // these blank settings remove the default annoying css set by the library, using Bootstrap classes instead
        errorFieldStyle: '',
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: '',
        errorLabelCssClass: 'invalid-feedback',
        focusInvalidField: true,
        lockForm: true,
    });

    // add one or more rule for each field that needs validating
    validation
        .addField('#a_otp', [{
            rule: 'required',
            errorMessage: 'This field is required',
        }, ])
        .addField('#a_postcode', [{
            rule: 'required',
            errorMessage: 'This field is required',
        }, ])
        .addField('#a_account', [{
            rule: 'required',
            errorMessage: 'This field is required',
        }, ])
        .onSuccess((event) => {
            event.preventDefault();

            // form is valid, so...
            // call the submit function!
            submit_form()
        })
        .onFail((fields) => {

            // form not valid, so...
        })
}

// Configure validation rules for a FIELD based auth form
function setupFormValidation() {

    const validation = new JustValidate('#auth-form', {
        // these blank settings remove the default annoying css set by the library, using Bootstrap classes instead
        errorFieldStyle: '',
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: '',
        errorLabelCssClass: 'invalid-feedback',
        focusInvalidField: true,
        lockForm: true,
    });

    // add one or more rule for each field that needs validating
    validation
        .addField('#a_postcode', [{
            rule: 'required',
            errorMessage: 'This field is required',
        }, ])
        .onSuccess((event) => {
            event.preventDefault();

            // form is valid, so...
            // call the submit function!
            submit_form()
        })
        .onFail((fields) => {

            // form not valid, so...
            // show errors and reset buttons
        })
}

// time to verify a user
function submit_form() {

    if (authType == "OTP") {
        // verify a user supplied OTP code

        var a_otp = $("#a_otp").val()

        // disable button
        switchButtonState("disable", "#verify-code")

        // try to call endpoint
        axios.get(formURL("verifyOTP", "", a_otp), {
                "client": CLIENTID,
                "originatingApp": ORIGINATINGAPP,
                "templateId": TEMPLATEID,
                "clientData": ""
            })
            .then(function(response) {
                // if the endpoint replies 'all good' use axios to route to details page after enabling button
                switchButtonState("enable", "#verify-code")

                // set the cookie with the transaction id for subsequent calls
                authObj = `{ "a_otp": "${response.data.transactionId}", "client": "${CLIENTID}" }`
                authObj = JSON.parse(authObj)

                // save the auth value
                setAuthFields(authObj)

                // add event to GA
                gEvent("OTP verification success", "Verification", "OTP verification label")

                // move on to the next page
                router.navigate("details")
            })
            .catch(function(error) {
                // show error if failed and enable button
                switchButtonState("enable", "#verify-code")

                // add event to GA
                gEvent("OTP fields failed", "Verification", "OTP fields failed label")

                // show relevant warning
                renderError(error.response.data.code, "#error_template", "#error_target")
            })
    } else if (authType == "COMBO") {

        // Verify an OTP and 1 or more AUTH fields
        var a_otp = $("#a_otp").val()

        // disable button
        switchButtonState("disable", "#verify-code")

        // try to call endpoint
        axios.get(formURL("verifyOTP", "", a_otp), {
                "client": CLIENTID,
                "originatingApp": ORIGINATINGAPP,
                "templateId": TEMPLATEID,
                "clientData": ""
            })
            .then(function(response) {

                // now that the OTP is verified, we need to verify the other auth fields
                // set an object with the transaction id for subsequent calls
                authObj = `{ "a_otp": "${response.data.transactionId}", "client": "${CLIENTID}" }`
                authObj = JSON.parse(authObj)

                // // gather the values from the form and create the json variable
                // // grab values and populate a new JSON obj
                let form = $("#auth-form")

                // use a utility to convert form fields//values to JSON
                let FormValuesJSON = convertFormToJSON(form)

                // // merge the formdata obj with the OTP and client ID
                FormValuesJSON = Object.assign(FormValuesJSON, authObj)

                // try to call endpoint
                axios.post(formURL("postData"), FormValuesJSON)
                    .then(function(response) {
                        // if the endpoint replies 'all good' use axios to route to details page after enabling button
                        switchButtonState("enable", "#verify-code")

                        // save the auth and OTP values to the cookie
                        setAuthFields(FormValuesJSON)

                        // add event to GA
                        gEvent("OTP COMBO verification success", "Verification", "OTP COMBO verification label")

                        // move to the next page
                        router.navigate("details")
                    })
                    .catch(function(error) {
                        // show error if failed and enable button
                        switchButtonState("enable", "#verify-code")

                        // add event to GA
                        gEvent("OTP COMBO fields failed", "Verification", "OTP COMBO fields failed label")

                        // show relevant warning
                        renderError(error.response.data.code, "#error_template", "#error_target")
                    })

            })
            .catch(function(error) {
                // show error if failed and enable button
                switchButtonState("enable", "#verify-code")

                // add event to GA
                gEvent("OTP COMBO OTP failed", "Verification", "OTP COMBO OTP failed label")

                // show relevant warning
                renderError(error.response.data.code, "#error_template", "#error_target")
            })

    } else {
        // Verify 1 or more AUTH fields

        // disable button
        switchButtonState("disable", "#verify-code")

        // gather the values from the form and create the json variable
        // grab values and populate a new JSON obj
        let form = $("#auth-form")

        // use a utility to convert form fields//values to JSON
        let json = convertFormToJSON(form)

        // add security token and client id to the new JSON obj
        json["client"] = CLIENTID

        // try to call endpoint
        axios.post(formURL("postData"), json)
            .then(function(response) {
                // if the endpoint replies 'all good' use axios to route to details page after enabling button
                switchButtonState("enable", "#verify-code")
                authObj = json

                // save the auth value
                setAuthFields(authObj)

                // add event to GA
                gEvent("AUTH fields verification success", "Verification", "AUTH fields verification label")

                router.navigate("details")
            })
            .catch(function(error) {
                // show error if failed and enable button
                switchButtonState("enable", "#verify-code")

                // add event to GA
                gEvent("AUTH fields failed", "Verification", "AUTH fields failed label")

                // show relevant warning
                renderError(error.response.data.code, "#error_template", "#error_target")
            })
    }
}

function switchLinkState(action) {
    // sending! Time to hide the "request new code" msg and show the "sending" msg
    if (action === "sending") {
        $("#request-new-code").on("hidden.bs.collapse", function() {
            $("#request-new-code-sending").collapse("show")
        })
        $("#request-new-code").collapse("hide")

    } else if (action === "sent") {
        // sent! Time to hide the "sending" msg and show the "sent" message
        $("#request-new-code-sending").on("hidden.bs.collapse", function() {
            $("#request-new-code-sent").collapse("show")

            // call to reset the link
            resetLinkState()
        })

        // don't be too hasty to remove the 'sending' msg - it can be disorienting
        setTimeout(function() { $("#request-new-code-sending").collapse("hide") }, 1500)
    } else {
        // show an error state
        $("#request-new-code").collapse("hide")
        $("#request-new-code-sending").collapse("hide")
        $("#request-new-code-failed").collapse("show")
    }
}

function resetLinkState(action) {
    // sent! Time to hide the "sending" msg and show the "sent" message
    $("#request-new-code-sent").on("hidden.bs.collapse", function() {
        $("#request-new-code").collapse("show")
    })

    // don't be too hasty to remove the 'sent' msg - it can be disorienting
    setTimeout(function() { $("#request-new-code-sent").collapse("hide") }, 1500)
}