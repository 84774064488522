// Navigo handles simple routing
import Navigo from "navigo"

// Axios to help with data+endpoints
const axios = require("axios")

// Handlebars for simple page templates
const Handlebars = require("handlebars")

// jQuery. Duh.
import jQuery from "jquery"
window.$ = window.jQuery = jQuery

// Bootstrap JS portions
import "bootstrap/dist/js/bootstrap.bundle"

// Import our exports, print mo money
import { reporting, gUser } from "./js/reporting.js"
import { renderTemplate, renderError } from "./js/render.js"
import { checkCookie, setuserId, refreshCookies, cookieTimer } from "./js/cookies.js"
import { formURL } from "./js/utils.js"

// importing JS for each view
import { welcomepage } from "./js/page_welcome.js"
import { authpage } from "./js/page_auth.js"
import { detailspage } from "./js/page_details.js"

// Create some constants to hold values for use in the Axios call and other items
// const BASE_URL = window.location.protocol + "//" + window.location.hostname + "/forms/"
const CLIENTID = process.env.APP_CLIENTID
const ORIGINATINGAPP = process.env.APP_ORIGINATINGAPP
const GOOGLEID = process.env.APP_GOOGLEID
const MESSAGE_FORBIDDEN = process.env.APP_MESSAGE_FORBIDDEN
const SITE_TITLE = process.env.POSTHTML_TITLE

// This will hold the type of validation required, data stored in the "or_type" field. Can be left blank or omitted
export let authType = ""

// Check to see if cookies are enabled - otherwise show an alert
if (checkCookie() === false) {
    var errorContent = { "error_type": "Cookies disabled", "error_message": "This site requires cookies to be enabled.", "error_details": "Please enable cookies in your browser settings and revisit the link you received." }
    renderTemplate(errorContent, "#alert_template", "#app")
}

// If a Google ID was supplied in the .env files - call in Google"s Tracking library
// TODO: Cater for multiple GoogleIDs
if (GOOGLEID != "") {
    console.log("Reporting started")
    $.getScript(
        "https://www.googletagmanager.com/gtag/js?id=" + GOOGLEID,
        function(data, textStatus, jqxhr) {}
    )
    window.dataLayer = window.dataLayer || []

    function gtag() { dataLayer.push(arguments) }
    gtag('js', new Date())

    // stop google tracking pages automatically - do manually
    gtag('config', GOOGLEID, { send_page_view: false });
}

// Create routes with Navigo
export const router = new Navigo("/")

// let the show begin
window.addEventListener("load", () => {

    if (checkCookie() === true) {
        // start the router
        router
            .on("/thankyou", () => {
                axios
                    .get(formURL("getSecureData"))
                    .then(function(user) {
                        // handle success
                        renderTemplate(user.data, "#thankyou_template", "#app")

                        // Change the page title
                        top.document.title = SITE_TITLE + " - Thank you"
                    })
                    .catch(function(error) {
                        // handle error
                        renderError(error.response.data.code)
                    })
                    .then(function() {
                        // always executed
                        afterRenderTemplate()

                        // Run reporting after each navigation 
                        reporting()

                    })
            }, {
                after: function(params) {
                    // after resolving
                },
            })
            .on("/details", () => {
                axios
                    .get(formURL("getSecureData"))
                    .then(function(user) {
                        // handle success
                        renderTemplate(user.data, "#details_template", "#app")

                        // Change the page title
                        top.document.title = SITE_TITLE + " - Details"
                    })
                    .catch(function(error) {
                        // handle error
                        renderError(error.response.data.code)
                    })
                    .then(function() {
                        // always executed
                        afterRenderTemplate()

                        // Run reporting after each navigation 
                        reporting()

                        detailspage()
                    })
            }, {
                after: function(params) {
                    // after resolving
                },
            })
            .on("/otp", () => {
                axios
                    .get(formURL("getData"))
                    .then(function(user) {
                        // handle success

                        // look at the record and decide the auth method for this record
                        authType = user.data.or_type

                        if (authType == "COMBO") {
                            // COMBO validation requires producing a form to accept auth field values and needs to allow the user to enter an OTP
                            renderTemplate(user.data, "#combo_template", "#app")
                        } else {
                            // OTP validation needs to allow the user to enter an OTP
                            renderTemplate(user.data, "#otp_template", "#app")
                        }

                        // Change the page title
                        top.document.title = SITE_TITLE + " - Verification"
                    })
                    .catch(function(error) {
                        // handle error
                        renderError(error.response.data.code)
                    })
                    .then(function() {
                        // always executed
                        afterRenderTemplate()

                        // Run reporting after each navigation 
                        reporting()

                        authpage()
                    })
            }, {
                after: function(params) {
                    // after resolving
                },
            })
            .on("/:id", ({ data }) => {

                // This is the root of the site right here.
                // Pulls the ID from the URL and sets to cookie
                setuserId(data.id)

                // Set the user to a Google UID
                gUser(data.id)

                axios
                    .get(formURL("getData"))
                    .then(function(user) {

                        // look at the record and decide the auth method for this record
                        authType = user.data.or_type

                        // handle success
                        if (authType == "OTP" || "COMBO") {
                            // OTP validation for OTP or COMBO needs to allow the user to request an OTP using this template
                            renderTemplate(user.data, "#welcome_template", "#app")
                        } else {
                            // FIELD validation only requires producing a form to accept auth field values using this template
                            renderTemplate(user.data, "#auth_template", "#app")
                        }

                        // Change the page title
                        top.document.title = SITE_TITLE + " - Welcome"
                    })
                    .catch(function(error) {
                        // handle error
                        renderError(error.response.data.code)
                    })
                    .then(function() {
                        // always executed
                        afterRenderTemplate()

                        // Run reporting after each navigation if a GOOGLEID is found in the .env files
                        // For the landing page we supply "/" so to remove the pURL from being added
                        // to the Google Analytics reporting data - for PII/security reasons.
                        reporting("/")

                        // Depending on the authType, we cater for the different flows by calling appropriate views
                        // OTP needs a view that allows the user to request an OTP to be sent
                        if (authType == "OTP" || "COMBO") {
                            welcomepage()
                        } else {
                            authpage()
                        }

                    })
            }, {
                after: function(params) {
                    // after resolving
                },
            })
            .on((match) => {
                renderError("NO_ID")
            })
            .notFound(() => {
                // renderTemplate("Not found")
                renderError({ "error_type": "Page not found1", "error_message": MESSAGE_FORBIDDEN }, '#not_found_template', '#app')

                // Change the page title
                top.document.title = SITE_TITLE + " - Page not found"

                // Run reporting after each navigation if a GOOGLEID is found in the .env files
                // We supply a generic "page-not-found" URL to report on in Google Analytics
                reporting("/page-not-found")
            })
            .resolve()
    }

    /**
     * Function that runs for all routes after templates have rendered
     */
    function afterRenderTemplate() {

        // let navigo check the links on the page for routing
        router.updatePageLinks()

        // Extend cookie lifetime after each navigation
        refreshCookies()

        // Clear and restart the cookie timer after each navigation/page render
        cookieTimer()
    }
})