// Axios to help with data+endpoints
const axios = require("axios")

import { switchButtonState, formURL } from "./utils.js"
import { renderError } from "./render.js"
import { gEvent } from "./reporting.js"
import { router } from "../app"

const CLIENTID = process.env.APP_CLIENTID
const ORIGINATINGAPP = process.env.APP_ORIGINATINGAPP
const TEMPLATEID = process.env.APP_TEMPLATEID

export function welcomepage() {

    // Enable the user to ask for a new OTP code
    $("#request-code").on("click", function() {
        // disable button
        switchButtonState("disable", "#request-code")

        // try to call endpoint
        axios.post(formURL("reqOTP"), {
                "client": CLIENTID,
                "originatingApp": ORIGINATINGAPP,
                "templateId": TEMPLATEID,
                "clientData": ""
            })
            .then(function(response) {
                // if the endpoint replies 'all good' use navigo to route user to OTP entry page and enable button
                switchButtonState("enable", "#request-code")

                // Add the event to reporting
                gEvent("OTP requested success", "Verification", "OTP requested successfully")

                router.navigate("otp")
            })
            .catch(function(error) {
                // show error if failed and enable button
                switchButtonState("enable", "#request-code")

                // Add the event to reporting
                gEvent("OTP request failed", "Verification", "OTP request failed")

                renderError(error.response.data.code)
            });
    })
}