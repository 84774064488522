// Axios to help with data+endpoints
const axios = require("axios")

// form validation library
import JustValidate from "just-validate"
import { switchButtonState, convertFormToJSON, formURL } from "./utils.js"
import { renderError } from "./render.js"
import Cookies from "js-cookie"
import { router } from "../app"
import { gEvent } from "./reporting.js"

// the main two functions needed to setup this view
export function detailspage() {
    setupFormValidation()
}

// Configure validation rules for the form
function setupFormValidation() {
    const validation = new JustValidate('#detail-form', {
        // these blank settings remove the default annoying css set by the library, using Bootstrap classes instead
        errorFieldStyle: '',
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: '',
        errorLabelCssClass: 'invalid-feedback',
        focusInvalidField: true,
        lockForm: true,
    });

    // add one or more rule for each field that needs validating
    validation
        .addField('#srw_email_address', [{
            rule: 'required',
            errorMessage: 'This field is required',
        }, {
            rule: 'email',
            errorMessage: 'This field should be in a valid email format',
        }, ])
        .addField('#srw_mobile', [{
            rule: 'number',
            errorMessage: 'This field can only contain numbers',
        }, {
            rule: 'required',
            errorMessage: 'This field is required',
        }, ])
        .addField('#srw_postal_address', [{
            rule: 'required',
            errorMessage: 'This field is required',
        }, {
            rule: 'minLength',
            value: 3,
        }, ])
        .addField('#srw_comm_pref', [{
            rule: 'required',
            errorMessage: 'Please make a selection',
        }, ])
        .addField('#srw_declaration1', [{
            rule: 'required',
            errorMessage: 'You must complete the declaration',
        }, ])
        .onSuccess((event) => {
            event.preventDefault();

            // form is valid, so...
            // call the submit function!
            submit_form()
        })
        .onFail((fields) => {

            // form not valid, so...
            // show errors and reset buttons
            console.log(`Form invalid!`)

            // add event to GA
            gEvent("Form validation fail", "Form submit", "Form validation fail label")
        })
}

function submit_form() {

    // disable button to throttle submits
    switchButtonState("disable", "#submit-button")

    // grab values and populate a new JSON obj
    const form = $("#detail-form")

    // call a function to convert form fields//values to JSON
    const FormJSON = convertFormToJSON(form)

    // concat security token and client id to the new JSON obj
    let AUTH_FIELDS = Cookies.get('AUTH')
    AUTH_FIELDS = JSON.parse(AUTH_FIELDS)
    let SubmitJSON = Object.assign(FormJSON, AUTH_FIELDS)

    // pass JSON object to endpoint via axios
    submitToEndpoint(SubmitJSON)
}

function submitToEndpoint(json) {

    // setup axios to post the data to the endpoint
    axios.post(formURL("postData"), json)
        .then(function(response) {
            // if the endpoint replies 'all good' use axios to route to OTP and enable button
            switchButtonState("enable", "#submit-button")

            // add event to GA
            gEvent("Form submit success", "Form submit", "Form submit success label")

            router.navigate("thankyou")
        })
        .catch(function(error) {
            // show error if failed and enable button
            switchButtonState("enable", "#submit-button")
            console.log(error)

            // add event to GA
            gEvent("Form submit failed", "Form submit", "Form submit failed label")

            // show relevant warning
            renderError(error.response.data.code, "#error_template", "#error_target")
        })
}